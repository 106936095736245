import { Routes } from "@angular/router";

export const listRouteConfig: Routes = [
  //
  {
    path: "defects",
    children: [
      {
        path: "",
        outlet: "defectlist",
        loadComponent: () =>
          import("./pages").then((m) => m.DefectListComponent),
      },
      {
        path: "**",
        redirectTo: "",
        pathMatch: "full",
      },
    ],
  },
  //
  {
    path: "details",
    children: [
      {
        path: "",
        outlet: "defectlist",
        loadComponent: () =>
          import("./pages").then((m) => m.ListDetailsComponent),
      },
      {
        path: "**",
        redirectTo: "",
        pathMatch: "full",
      },
    ],
  },
  // ** goes last
  { path: "**", redirectTo: "defects", pathMatch: "prefix" },
];
