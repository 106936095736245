import { Routes } from "@angular/router";
import {
  AuthGuard,
  TokenGuard,
  OptionsAdminGuard,
  checkoutGuard,
  billingRedirectGuard,
} from "src/app/utils/guards";

import { GetAppPage } from "./get-app/get-app.page";
import { WebLoginPage } from "./web-login/web-login.page";

import { projectRouteConfig } from "./project/project.routes";
import { optionRouteConfig } from "./options/options.routes";
import { PageNotFound } from "./page-not-found/page-not-found.component";

export const routeConfig: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "projects", redirectTo: "login", pathMatch: "full" },
  {
    path: ":tenantid/projects",
    loadComponent: () =>
      import("./home/home.component").then((m) => m.HomePage),
    canActivate: [AuthGuard],
  },
  {
    path: ":tenantid/projects/create",
    loadComponent: () =>
      import("./create-project/create-project.page").then(
        (m) => m.CreateProjectPage
      ),
    canActivate: [AuthGuard],
  },
  {
    path: ":tenantid/project/:projectid",
    children: projectRouteConfig,
    canActivate: [AuthGuard],
  },
  // whacked here so it uses the base router outlet, plays up when in options
  {
    path: ":tenantid/options/reports/template/:templateId",
    loadComponent: () =>
      import("./report-designer/report-designer.page").then(
        (m) => m.ReportDesignerPage
      ),
    canActivate: [OptionsAdminGuard],
  },
  {
    path: ":tenantid/options",
    children: optionRouteConfig,
    canActivate: [AuthGuard],
  },
  // Used for redirect purposes only.
  // /pricing page points here then needs to redirect to next route
  {
    path: "billing/:productId/:quantity",
    component: PageNotFound,
    canActivate: [billingRedirectGuard],
  },
  {
    path: ":tenantid/billing/:productId/:quantity",
    loadComponent: () =>
      import("./checkout/checkout.page").then((m) => m.CheckoutPage),
    canActivate: [checkoutGuard],
  },
  {
    path: "login",
    loadComponent: () =>
      import("./login/login.component").then((m) => m.LoginPage),
    canActivate: [TokenGuard],
  },
  {
    path: "client",
    loadChildren: () =>
      import("./client-home/client-home.module").then(
        (m) => m.ClientHomePageModule
      ),
  },
  {
    path: "confirmation/:referenceId/:productId/:quantity",
    loadComponent: () =>
      import("./confirmation/confirmation.page").then(
        (m) => m.ConfirmationPage
      ),
  },
  {
    path: "web-login/:redirectTo",
    component: WebLoginPage,
  },
  {
    path: "install",
    component: GetAppPage,
  },
  // TODO: Make a 404 page
  { path: "**", component: PageNotFound },
];
