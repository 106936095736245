import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";

import { Browser } from "@capacitor/browser";

import { getAuth, signInWithCustomToken } from "firebase/auth";

import { links } from "src/app/utils/constants";
import { AnalyticsService, LogService, LoadingController } from "../services";

@Component({
  selector: "app-web-login",
  templateUrl: "./web-login.page.html",
  styleUrls: ["./web-login.page.scss"],
  standalone: true,
})
export class WebLoginPage implements OnInit {
  /** @ignore */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loadingCtrl: LoadingController,
    private log: LogService,
    private analytics: AnalyticsService
  ) {
    this.analytics.pageView("Account - AutoLogin", "web-login");
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params: Params) => {
      await this.loadingCtrl.present({
        message: "Logging In...",
      });

      try {
        const token = params["t"];

        if (token) {
          const auth = getAuth();

          const result = await signInWithCustomToken(auth, token);

          let loginMethod = "web-login";
          if (result.providerId && result.user) {
            loginMethod = result.providerId;
          }

          this.analytics.login(loginMethod, result.user.email ?? "");

          this.log.identifyUser({
            email: result.user.email,
            id: result.user.uid,
          });

          if (this.route.snapshot.params["redirectTo"] == "pricing") {
            Browser.open({
              url: links.pricing,
              windowName: "_self",
            });
          } else {
            await this.router.navigateByUrl("/");
          }
        } else {
          await this.router.navigateByUrl("/login");
        }
      } catch (err) {
        this.log.exception(err);
        await this.router.navigateByUrl("/login");
      }

      await this.loadingCtrl.dismiss();
    });
  }
}
