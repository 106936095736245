import { Routes } from "@angular/router";

export const simpleReportRouteConfig: Routes = [
  {
    path: "",
    loadComponent: () =>
      import("./report-builder.page").then((m) => m.ReportBuilderPage),
    children: [
      {
        path: "coverpage",
        children: [
          {
            path: "",
            outlet: "reportsettings",
            loadComponent: () =>
              import("./components").then((m) => m.ReportsPage),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "insidecover",
        children: [
          {
            path: "",
            outlet: "reportsettings",
            loadComponent: () =>
              import("./components").then(
                (m) => m.InsideCoverSettingsComponent
              ),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "contents",
        children: [
          {
            path: "",
            outlet: "reportsettings",
            loadComponent: () =>
              import("./components").then((m) => m.ContentsSettingsComponent),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "defectlist",
        children: [
          {
            path: "",
            outlet: "reportsettings",
            loadComponent: () =>
              import("./components").then((m) => m.DefectListSettingsComponent),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "",
        redirectTo: "coverpage",
        pathMatch: "full",
      },
    ],
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];
