import { Routes } from "@angular/router";

import { OptionsAdminGuard } from "src/app/utils/guards";
import { simpleReportRouteConfig } from "../report-builder/report-builder.routes";

export const optionRouteConfig: Routes = [
  {
    path: "report-simple",
    canActivate: [OptionsAdminGuard],
    children: simpleReportRouteConfig,
  },
  {
    path: "",
    loadComponent: () => import("./options.page").then((m) => m.OptionsPage),
    children: [
      {
        path: "reports",
        children: [
          {
            path: "",
            outlet: "settings",
            canActivate: [OptionsAdminGuard],
            loadComponent: () =>
              import("./pages/report-templates/report-templates.page").then(
                (m) => m.ReportTemplatesPage
              ),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
      //
      {
        path: "account",
        children: [
          {
            path: "",
            outlet: "settings",
            loadComponent: () => import("./pages").then((m) => m.AccountPage),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
      //
      {
        path: "contractors",
        canActivate: [OptionsAdminGuard],
        children: [
          {
            path: "",
            outlet: "settings",
            loadComponent: () =>
              import("./pages").then((m) => m.ContractorsPage),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
      //
      {
        path: "team",
        canActivate: [OptionsAdminGuard],
        children: [
          {
            path: "",
            outlet: "settings",
            loadComponent: () => import("./pages").then((m) => m.TeamPage),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
      //
      {
        path: "company",
        children: [
          {
            path: "",
            outlet: "settings",
            loadComponent: () => import("./pages").then((m) => m.TenantPage),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
      //
      {
        path: "billing",
        canActivate: [OptionsAdminGuard],
        children: [
          {
            path: "",
            outlet: "settings",
            loadComponent: () => import("./pages").then((m) => m.BillingPage),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "",
        children: [
          {
            path: "",
            outlet: "settings",
            loadComponent: () =>
              import("./pages").then((m) => m.OptionsSummaryPage),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
    ],
  },

  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];
