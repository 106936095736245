import { Routes } from "@angular/router";

import { listRouteConfig } from "../list/list.routes";

export const projectRouteConfig: Routes = [
  // Load list module for this route
  {
    path: "list/:listid",
    loadComponent: () => import("../list/list.page").then((m) => m.ListPage),
    children: listRouteConfig,
  },
  //
  {
    path: "",
    loadComponent: () =>
      import("./project-scaffold.page").then((m) => m.ProjectScaffoldPage),
    children: [
      //
      {
        path: "lists",
        children: [
          {
            path: "",
            outlet: "project",
            loadComponent: () =>
              import("./pages").then((m) => m.ListsComponent),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
      //
      {
        path: "details",
        children: [
          {
            path: "",
            outlet: "project",
            loadComponent: () =>
              import("./pages").then((m) => m.DetailsComponent),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
      //
      {
        path: "reports",
        children: [
          {
            path: "",
            outlet: "project",
            loadComponent: () =>
              import("./pages").then((m) => m.ReportsComponent),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
      //
      {
        path: "settings",
        children: [
          {
            path: "",
            outlet: "project",
            loadComponent: () =>
              import("./pages").then((m) => m.SettingsComponent),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
      //
      {
        path: "team",
        children: [
          {
            path: "",
            outlet: "project",
            loadComponent: () =>
              import("./pages").then((m) => m.ProjectTeamComponent),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
      //
      {
        path: "assignments",
        children: [
          {
            path: "",
            outlet: "project",
            loadComponent: () =>
              import("./pages").then((m) => m.SettingsContractorComponent),
          },
          {
            path: "**",
            redirectTo: "",
            pathMatch: "full",
          },
        ],
      },
      // ** goes last
      { path: "**", redirectTo: "lists", pathMatch: "prefix" },
    ],
  },
  // ** goes last
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];
